import { dataLayerEvent } from "features/dataLayer";
import { useTranslation } from "features/translations";
import { useContext, useEffect, useState } from "react";
import {
  Action,
  Heading3,
  Icon,
  InputType as InputItem,
  Paragraph,
} from "shared/components";
import { getUtmParamsFromLocalStorage } from "shared/utils/utmUtils/utmUtils";

import { FormContent } from "../Form.Provider";
import { FormSubmitStatus } from "../FormSubmitStatus";
import styles from "./FormRegistration.module.scss";

export const FormRegistration = ({ info, instanceId }) => {
  const { t } = useTranslation();
  const {
    data,
    type,
    title,
    buttonText,
    fields,
    canBeSubmitted,
    metaData: initialMetaData,
    pending,
    onHandle,
    onSubmit,
  } = useContext(FormContent);

  const [utmParams, setUtmParams] = useState({});
  const [metaData, setMetaData] = useState({
    ...initialMetaData,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedUtmParams = getUtmParamsFromLocalStorage();

      const filteredUtmParams = Object.entries(storedUtmParams).reduce(
        (acc, [key, value]) => {
          if (value) acc[key] = value;
          return acc;
        },
        {}
      );

      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        ...filteredUtmParams,
        pageUrl: `${window.location.origin}${window.location.pathname}`,
        instanceId: instanceId ?? "Instance ID not provided",
      }));

      setUtmParams(filteredUtmParams);
    }
  }, []);

  useEffect(() => {
    if (canBeSubmitted) {
      onSubmit({ type, data, metaData });
      dataLayerEvent("registration", {
        email: data?.email,
        phone: data?.phone,
      });
    }
  }, [canBeSubmitted, data, metaData, onSubmit, type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onHandle();
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Heading3 className={styles.title}>{t(title)}</Heading3>
        <div className={styles.registration}>
          {info}
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles["input-group"]}>
              {fields.map((input) => {
                return <InputItem key={input.id} {...input} />;
              })}
              <Action className={styles.submit} disabled={pending}>
                <Paragraph color="brand" appearance="bold">
                  {t(buttonText)}
                </Paragraph>
                <span className={styles.arrow}>
                  <Icon className={styles.icon} name="arrow_right" />
                </span>
              </Action>
            </div>
          </form>
        </div>
      </div>
      <FormSubmitStatus />
    </>
  );
};
