import classNames from "classnames";
import { dataLayerEvent } from "features/dataLayer";
import { useTranslation } from "features/translations";
import { useContext, useEffect, useState } from "react";
import {
  Action,
  Icon,
  InputType as InputItem,
  Paragraph,
} from "shared/components";
import { getUtmParamsFromLocalStorage } from "shared/utils/utmUtils/utmUtils";

import { FormContent } from "../Form.Provider";
import { FormSubmitStatus } from "../FormSubmitStatus";
import styles from "./FormSubscription.module.scss";

export const FormSubscription = ({ className, instanceId }) => {
  const { t } = useTranslation();
  const [utmParams, setUtmParams] = useState({});
  const [metaData, setMetaData] = useState({});
  const {
    data,
    type,
    title,
    fields,
    canBeSubmitted,
    pending,
    onHandle,
    onSubmit,
  } = useContext(FormContent);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedUtmParams = getUtmParamsFromLocalStorage();

      const filteredUtmParams = Object.entries(storedUtmParams).reduce(
        (acc, [key, value]) => {
          if (value) acc[key] = value;
          return acc;
        },
        {}
      );

      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        ...filteredUtmParams,
        pageUrl: `${window.location.origin}${window.location.pathname}`,
        instanceId: instanceId ?? "Instance ID not provided",
      }));
    }
  }, []);

  useEffect(() => {
    if (canBeSubmitted) {
      const fullData = {
        ...data,
      };

      onSubmit({ type, data: fullData, metaData });
      dataLayerEvent("subscription", {
        email: data?.email,
        phone: data?.phone,
      });
    }
  }, [canBeSubmitted, data, onSubmit, type, metaData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onHandle();
  };

  return (
    <>
      <div className={classNames(styles.wrapper, styles[className])}>
        <div className={styles.blocks}>
          <Paragraph className={classNames(styles.title, className)}>
            {t(title)}
          </Paragraph>
          <div className={styles.subscribe}>
            <form onSubmit={handleSubmit}>
              <div className={styles.form}>
                <div className={styles["input-group"]}>
                  {fields.map((input) => {
                    return <InputItem key={input.id} {...input} />;
                  })}
                </div>
                <Action disabled={pending} className={styles.circles}>
                  <span className={styles.arrow}>
                    <Icon className={styles.icon} name="arrow_right" />
                  </span>
                </Action>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FormSubmitStatus />
    </>
  );
};
