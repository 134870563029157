import { FormProvider } from "./Form.Provider";
import { FormApplication } from "./FormApplication";
import { FormRegistration } from "./FormRegistration";
import { FormSubscription } from "./FormSubscription";

export const TYPE_CARD_MAPPING = {
  application: FormApplication,
  subscription: FormSubscription,
  registration: FormRegistration,
};

export const FormType = ({
  item,
  metaData,
  info,
  className,
  onReset,
  instanceId,
}) => {
  const Form = TYPE_CARD_MAPPING[item?.title];

  if (Form) {
    return (
      <FormProvider
        formData={item.formData}
        type={item.id}
        metaData={metaData}
        onResetCallback={onReset}
      >
        <Form className={className} info={info} instanceId={instanceId} />
      </FormProvider>
    );
  }
  return null;
};
