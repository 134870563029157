export { default as address } from "./address.svg";
export { default as arrow_right } from "./arrow-right.svg";
export { default as arrow_slider_next } from "./arrow-slider-next.svg";
export { default as arrow_slider_prev } from "./arrow-slider-prev.svg";
export { default as back } from "./back.svg";
export { default as blockquote } from "./blockquote.svg";
export { default as calendar } from "./calendar.svg";
export { default as checkmark } from "./checkmark.svg";
export { default as checkmark_thin } from "./checkmark-thin.svg";
export { default as chevron_down } from "./chevron-down.svg";
export { default as chevron_left } from "./chevron-left.svg";
export { default as chevron_right } from "./chevron-right.svg";
export { default as chevron_up } from "./chevron-up.svg";
export { default as clock } from "./clock.svg";
export { default as close } from "./close.svg";
export { default as close_white } from "./close_white.svg";
export { default as comment } from "./comment.svg";
export { default as done } from "./done.svg";
export { default as download } from "./download.svg";
export { default as dropdown } from "./dropdown.svg";
export { default as dropdown_arrow } from "./dropdown-arrow.svg";
export { default as email } from "./email.svg";
export { default as facebook } from "./facebook.svg";
export { default as file } from "./file.svg";
export { default as instagram } from "./instagram.svg";
export { default as large_arrow_down } from "./large-arrow-down.svg";
export { default as large_arrow_right } from "./large-arrow-right.svg";
export { default as linkedin } from "./linkedin.svg";
export { default as loader } from "./loader.svg";
export { default as long_arrow_left } from "./long-arrow-left.svg";
export { default as menu } from "./menu.svg";
export { default as message } from "./message.svg";
export { default as more } from "./more.svg";
export { default as pin } from "./pin.svg";
export { default as play } from "./play.svg";
export { default as plus } from "./plus.svg";
export { default as profile } from "./profile.svg";
export { default as rounded_chevron_down } from "./rounded-chevron-down.svg";
export { default as share } from "./share.svg";
export { default as tag } from "./tag.svg";
export { default as tel } from "./tel.svg";
export { default as telegram } from "./telegram.svg";
export { default as youtube } from "./youtube.svg";
